
import { HeaderFieldType } from '@/shared/types';


export const employeesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: true,
        value: 'index',
    },
    { text: 'Imię i nazwisko', value: 'name' },
    { text: 'Funkcja', value: 'role' },
    { text: 'Forma zatrudnienia', value: 'employmentType' },
    { text: 'Firma', value: 'companyName' },
    { text: 'Akcje', value: 'actions' },
];

export const employmentTypesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: true,
        value: 'index',
    },
    { text: 'Nazwa formy zatrudnienia', value: 'name' },
    { text: 'Akcje', value: 'actions' },
];

export const rolesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: true,
        value: 'index',
    },
    { text: 'Nazwa funkcji', value: 'name' },
    { text: 'Akcje', value: 'actions' },
];
