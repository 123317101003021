

/**
 *  Dependencies list:
 *  - shared
 *  - module
 *  - icons
 */
import { Component } from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { personnelStatuses } from '@/shared/config/personnel';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import { ModuleState } from '@/shared/state/template/module-state';

import {
    employeesModule,
    Employee,
    EmployeeDataRequest,
} from '../shared';

import { mdiEmail, mdiPhone } from '@mdi/js';

@Component({
    props: {
        id: String,
    },
    components: {
        TopToolbar,
    }
})
export default class EmployeesDetails extends DetailsComponent<EmployeeDataRequest> {
    public store: ModuleState<Employee, EmployeeDataRequest> = this.$store.state.personnelState;
    public actionsTypes = employeesModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public icons = {
        mdiEmail,
        mdiPhone,
    };

    get status() {
        return this.item.status && personnelStatuses ?
            personnelStatuses.find((status: any) => status.id === this.item.status) :
            null;
    }

    public created() {
        if (this.id) {
            this.fetchDetails();
        }
    }
}
