
    /**
     *  Dependencies list:
     *  - shared
     *  - module
     *  - icons
     */
    import {Component, Prop} from 'vue-property-decorator';
    import ItemsTable from '@/shared/components/layout/list.vue';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import {ModuleProps} from '@/shared/state/template/module-props';
    import { ModuleState } from '@/shared/state/template/module-state';
    import { HeaderFieldType } from '@/shared/types';

    import EmployeesDetails from './details.vue';
    import EmployeesForm from './form.vue';
    import {
        employeesModule,
        employeesListHeaders,
        Employee,
        EmployeeDataRequest
    } from '../shared';

    @Component({
        components: {
            ItemsTable,
            EmployeesDetails,
            EmployeesForm
        },
    })
    export default class EmployeesList extends ListComponent<Employee, EmployeeDataRequest> {
        public actionsTypes = employeesModule.actionsTypes;

        @Prop ({ default() { return employeesListHeaders; }}) public declare headers: HeaderFieldType[];
        @Prop ({ default() { return employeesModule.mutationsTypes; }}) public declare mutationTypes;
        @Prop ({ default: employeesModule.actionsTypes.FETCH_DATA }) public declare fetchAction: string;
        @Prop ({ default: employeesModule.actionsTypes.REMOVE_ITEM }) public declare removeAction: string;

        public store: ModuleState<Employee, EmployeeDataRequest> = this.$store.state.personnelState;
        public props: ModuleProps = employeesModule.moduleProps;

        public mounted() {
            this.fetchData();
        }

    }
