import { PersonnelRole } from '@/modules/personnel/shared/models/personnel-role';
import { EmploymentType } from '../models/employment-type';

export class EmployeeDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public full_name: string = '';
    // tslint:disable-next-line:variable-name
    public personnel_role_id: number = 0;
    // tslint:disable-next-line:variable-name
    public personnel_employment_type_id: number = 0;
    // tslint:disable-next-line:variable-name
    public company_name: string = '';
    public email: string = '';
    public phone: string = '';
    public qualifications: string = '';
    public profession: string = '';
    public internship: number = 0;
    public education: string = '';
    public hourly: number = 0;
    public monthly: number = 0;
    // tslint:disable-next-line:variable-name
    public place_of_residence: string = '';
    public description: string = '';
    public status: string = '';

    public role?: PersonnelRole;
    // tslint:disable-next-line:variable-name
    public employment_type?: EmploymentType;
    public name: string = '';
}
