
    /**
     *  Dependencies list:
     *  - shared
     *  - module
     *  - icons
     */
    import {Component, Prop} from 'vue-property-decorator';
    import ItemsTable from '@/shared/components/layout/list.vue';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import {ModuleProps} from '@/shared/state/template/module-props';
    import { ModuleState } from '@/shared/state/template/module-state';
    import { HeaderFieldType } from '@/shared/types';

    import RolesForm from './form.vue';
    import {
        rolesModule,
        rolesListHeaders,
        PersonnelRole,
        PersonnelRoleDataRequest
    } from '@/modules/personnel/shared';

    @Component({
        components: {
            ItemsTable,
            RolesForm
        },
    })
    export default class RolesList extends ListComponent<PersonnelRole, PersonnelRoleDataRequest> {
        public actionsTypes = rolesModule.actionsTypes;

        @Prop ({ default() { return rolesListHeaders; }}) public declare headers: HeaderFieldType[];
        @Prop ({ default() { return rolesModule.mutationsTypes; }}) public declare mutationTypes;
        @Prop ({ default: rolesModule.actionsTypes.FETCH_DATA }) public declare fetchAction: string;
        @Prop ({ default: rolesModule.actionsTypes.REMOVE_ITEM }) public declare removeAction: string;

        public store: ModuleState<PersonnelRole, PersonnelRoleDataRequest> =
            this.$store.state.personnelState.rolesState;
        public props: ModuleProps = rolesModule.moduleProps;

        public mounted() {
            this.fetchData();
        }

    }
