
    /**
     *  Dependencies list:
     *  - shared
     *  - module
     */
    import { Component, Prop } from 'vue-property-decorator';
    import FormComponent from '@/shared/components/layout/form/form-component';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';
    import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
    import {logger} from '@/shared/services';
    import { ModuleState } from '@/shared/state/template/module-state';
    import {
        employeesModule,
        EmployeeDataRequest,
        Employee,
    } from '../shared';
    import { rolesModule } from '@/modules/personnel/shared/state/settings/roles';
    import { employmentTypesModule } from '@/modules/personnel/shared/state/settings/employment-types';
    import { personnelStatuses } from '@/shared/config/personnel';
    @Component({
        components: {
            FormActions,
            ActionConfirmDialog,
        },
        props: {},
    })
    export default class EmployeesForm extends FormComponent<EmployeeDataRequest> {
        public employeesActionsTypes = employeesModule.actionsTypes;
        public rolesActionsTypes = rolesModule.actionsTypes;
        public employmentTypesActionsTypes = employmentTypesModule.actionsTypes;
        public store = this.$store.state.personnelState;
        public storeAction = this.employeesActionsTypes.STORE_ITEM;
        public updateAction = this.employeesActionsTypes.UPDATE_ITEM;
        public requestData = new EmployeeDataRequest();
        public statuses = personnelStatuses;

        public created() {
            //
        }

        public mounted() {
            this.form = this.$refs.createEmployeesForm;

            this.$store.dispatch(this.employeesActionsTypes.FETCH_DATA, { simple: true })
            .catch((error) => {
                logger.error(error);
            });

            this.$store.dispatch(this.rolesActionsTypes.FETCH_DATA, { simple: true })
            .catch((error) => {
                logger.error(error);
            });

            this.$store.dispatch(this.employmentTypesActionsTypes.FETCH_DATA, { simple: true })
            .catch((error) => {
                logger.error(error);
            });
        }

        get roles() {
            return this.store.rolesState.dataList;
        }

        get employmentTypes() {
            return this.store.employmentTypesState.dataList;
        }

        get formTitle() {
            return this.edit ? 'Edytuj Pracownika' : 'Dodaj Pracownika';
        }

        get employeesListLoading() {
            return this.store ? this.store.loadingList : false;
        }

        get rolesListLoading() {
            return this.store ? this.store.rolesState.loadingList : false;
        }

        get employmentTypesListLoading() {
            return this.store ? this.store.employmentTypesState.loadingList : false;
        }
    }
