
    /**
     *  Dependencies list:
     *  - shared
     *  - module
     */
    import { Component, Prop } from 'vue-property-decorator';
    import FormComponent from '@/shared/components/layout/form/form-component';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';
    import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
    import { logger } from '@/shared/services';
    import { ModuleState } from '@/shared/state/template/module-state';
    import {
        employmentTypesModule,
        EmploymentTypeDataRequest,
        EmploymentType,
    } from '@/modules/personnel/shared';

    @Component({
        components: {
            FormActions,
            ActionConfirmDialog,
        },
        props: {},
    })
    export default class EmploymentTypesForm extends FormComponent<EmploymentTypeDataRequest> {
        @Prop(Boolean) public declare show: boolean;
        @Prop({ type: Boolean, default: true }) public declare next: boolean;
        @Prop({ type: Boolean, default: false }) public declare edit: boolean;
        @Prop(Object) public declare itemData: EmploymentTypeDataRequest;

        public actionTypes = employmentTypesModule.actionsTypes;
        public store: ModuleState<EmploymentType, EmploymentTypeDataRequest> =
            this.$store.state.personnelState.employmentTypesState;
        public storeAction = this.actionTypes.STORE_ITEM;
        public updateAction = this.actionTypes.UPDATE_ITEM;
        public requestData = new EmploymentTypeDataRequest();

        public created() {
            //
        }

        public mounted() {
            this.form = this.$refs.createEmploymentTypesForm;

            this.$store.dispatch(this.actionTypes.FETCH_DATA, { simple: true })
                .catch((error) => {
                    logger.error(error);
                });

        }

        get formTitle() {
            return this.edit ? 'Edytuj Formę Zatrudnienia' : 'Dodaj Formę Zatrudnienia';
        }

        get employmentTypesListLoading() {
            return this.store ? this.$store.state.employmentTypesState.loadingList : false;
        }
    }
