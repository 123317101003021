
    /**
     *  Dependencies list:
     *  - shared
     *  - module
     *  - icons
     */
    import { Component, Prop } from 'vue-property-decorator';
    import ItemsTable from '@/shared/components/layout/list.vue';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { ModuleProps } from '@/shared/state/template/module-props';
    import { ModuleState } from '@/shared/state/template/module-state';
    import { HeaderFieldType } from '@/shared/types';

    import EmploymentTypesForm from './form.vue';
    import {
        employmentTypesModule,
        employmentTypesListHeaders,
        EmploymentType,
        EmploymentTypeDataRequest,
    } from '@/modules/personnel/shared';

    @Component({
        components: {
            ItemsTable,
            EmploymentTypesForm,
        },
    })
    export default class EmploymentTypesList extends ListComponent<EmploymentType, EmploymentTypeDataRequest> {
        public actionsTypes = employmentTypesModule.actionsTypes;

        @Prop({
            default() {
                return employmentTypesListHeaders;
            },
        }) public declare headers: HeaderFieldType[];
        @Prop({
            default() {
                return employmentTypesModule.mutationsTypes;
            },
        }) public declare mutationTypes;
        @Prop({ default: employmentTypesModule.actionsTypes.FETCH_DATA }) public declare fetchAction: string;
        @Prop({ default: employmentTypesModule.actionsTypes.REMOVE_ITEM }) public declare removeAction: string;

        public store: ModuleState<EmploymentType, EmploymentTypeDataRequest> =
            this.$store.state.personnelState.employmentTypesState;
        public props: ModuleProps = employmentTypesModule.moduleProps;

        public mounted() {
            this.fetchData();
        }

    }
